<main *ngIf="!loading else loadingTemplate" class="gap-3 flex-column d-flex">
  <table>
    <thead>
      <tr>
        <th>
          <header id="main-header" class="gap-3 d-flex">
            <div class="gap-3 flex-column flex-fill d-flex">
              <h2> موسسه الیتیم الخیریه <span> ({{ translatePipe.transform('PROFILE_FAMILY') }}) </span> </h2>
              <div class="gap-3 d-flex">
                <div class="row flex-fill gy-2">
                  <div class="col-md-12"> <label> {{ translatePipe.transform('FAMILY_CODE') }} : </label> <span> {{ family.info.code }} </span> </div>
                  <div class="col-md-6"> <label> {{ translatePipe.transform('CREATION_DATE') }} : </label> <pre> {{ family.info.firstFinalConfirm }} </pre> </div>
                  <div class="col-md-6"> <label> {{ translatePipe.transform('LAST_UPDATE_DATE') }} : </label> <pre> {{ family.info.lastFinalConfirm }} </pre> </div>
                  <div class="col-md-6"> <label> {{ translatePipe.transform('BRANCH') }} : </label> <span> {{ selected.branchTitle }} </span> </div>
                  <div class="col-md-6"> <label> {{ translatePipe.transform('PRINT_DATE') }} : </label> <pre> {{ printDate }} </pre> </div>
                </div>
              </div>
            </div>
            <img src="assets/images/logo-alyatim-2.svg" height="120" alt="" />
          </header>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <section class="gap-3 flex-column d-flex">
            <table>
              <thead class="with-title"> <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('FAMILY_INFO') }} </h3> </th> </tr> </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="row gy-3">
                      <div class="col-md-6"> <label> {{ translatePipe.transform('STATISTICAL_NUMBER') }} : </label> <span> {{ family.info.statisticalNo }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('STOCK_CARD_NUMBER') }} : </label> <span> {{ family.info.rationCardNumber }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('DESERT_AMOUNT') }} : </label> <span> {{ family.info.entitlementAmount + ' (' + translatePipe.transform('IQD') + ')' }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('BENEFICIARY_TYPE') }} : </label> <span> {{ family.info.beneficiaryType }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('FAMILY_TAGS') }} : </label> <span> {{ family.info.tags }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('FAMILY_TYPE') }} : </label> <span> {{ family.info.familyType }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('ECONOMIC_STATUS') }} : </label> <span> {{ family.info.economicStatus }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('FAMILY_FOOD_CONSUMPTION_STATUS') }} : </label> <span> {{ family.info.foodIntakeStatus }} </span> </div>
                      <div class="col-md-6">
                        <label> {{ translatePipe.transform('IS_GUARDIAN_WITH_FAMILY') }} </label>
                        <i [class]="'bx fw-bolder font-size-20 position-relative mx-1 ' + (family.info.guardianLiveWithFamily ? 'bx-check' : 'bx-x')" style="top: 3px"> </i>
                      </div>
                      <div class="col-md-6">
                        <label> {{ translatePipe.transform('IS_FAMILY_ELIGIBLE') }} </label>
                        <i [class]="'bx fw-bolder font-size-20 position-relative mx-1 ' + (family.info.isIncluded ? 'bx-check' : 'bx-x')" style="top: 3px"> </i>
                      </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('LACK_OF_INCLUSION_REASON') }} : </label> <span> {{ family.info.reasonNotIncluded }} </span> </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <ng-container *ngIf="family.attachData && family.attachData.info">
              <table>
                <thead class="with-title"> <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('FAMILY_INFO_ATTACH') }} </h4> </th> </tr> </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="row gy-3">
                        <div class="col-md-6"> <label> {{ translatePipe.transform('COUNT_UPLOADED') }} : </label> <span> {{ family.attachData.info.count }} </span> </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </section>
          <section class="gap-3 flex-column d-flex">
            <table>
              <thead class="with-title"> <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('LOCATION_INFO') }} </h3> </th> </tr> </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="row gy-3">
                      <div class="col-md-6"> <label> {{ translatePipe.transform('OWNERSHIP_TYPE') }} : </label> <span> {{ family.addressInfo?.ownershipType }} </span> </div>
                      <div class="col-md-6"> <label class="fw-bold"> {{ 'OWNER_FULL_NAME' | translate }} : </label> {{ family.addressInfo?.familyMemberFullName || family.addressInfo?.ownerFullName }} </div>
                      <div *ngIf="family.addressInfo?.ownerRelationType" class="col-md-6"> <label class="fw-bold"> {{ 'OWNER_RELATION' | translate }} : </label> {{ family.addressInfo?.ownerRelationType }} </div>
                      <div *ngIf="family.addressInfo?.ownerPhone" class="col-md-6"> <label class="fw-bold"> {{ 'OWNER_PHONE' | translate }} : </label> {{ family.addressInfo?.ownerPhone }} </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('BUILDING_TYPE') }} : </label> <span> {{ family.addressInfo?.buildingType }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('HOUSE_AREA') }} : </label> <span> {{ family.addressInfo?.houseArea + ' (' + translatePipe.transform('SQUARE_METER') + ')' }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('HOUSE_STATUS') }} : </label> <span> {{ family.addressInfo?.houseQualityStatus }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('HOUSE_ROOM_COUNT') }} : </label> <span> {{ family.addressInfo?.houseRoomsNumber }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('FAMILY_ROOM_COUNT') }} : </label> <span> {{ family.addressInfo?.familyRoomsNumber }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('COUNT_OF_BATH_ROOM') }} : </label> <span> {{ family.addressInfo?.countofBathRoom }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('RENT_AMOUNT') }} : </label> <span> {{ family.addressInfo?.houseRentAmount + ' (' + translatePipe.transform('IQD') + ')' }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('FAMILY_RENT_AMOUNT') }} : </label> <span> {{ family.addressInfo?.familyRentAmount + ' (' + translatePipe.transform('IQD') + ')' }} </span> </div>
                      <div class="col-md-6">
                        <label> {{ translatePipe.transform('HAS_A_COPY_OF_THE_LEASE_BEEN_GIVEN_TO_THE_INSTITUTION') }} </label>
                        <i [class]="'bx fw-bolder font-size-20 position-relative mx-1 ' + (family.addressInfo?.isCopyOfLeaseReceived ? 'bx-check' : 'bx-x')" style="top: 3px"> </i>
                      </div>
                      <div class="col-md-6">
                        <label> {{ translatePipe.transform('IS_NEED_TO_REBUILD') }} ? </label>
                        <i [class]="'bx fw-bolder font-size-20 position-relative mx-1 ' + (family.addressInfo?.needToBuild ? 'bx-check' : 'bx-x')" style="top: 3px"> </i>
                      </div>
                      <div class="col-md-6">
                        <label> {{ translatePipe.transform('DO_FAMILY_LIVES_ALONE') }} </label>
                        <i [class]="'bx fw-bolder font-size-20 position-relative mx-1 ' + (family.addressInfo?.familyLiveAlone ? 'bx-check' : 'bx-x')" style="top: 3px"> </i>
                      </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('THE_FAMILY_SHE_LIVES_WITH') }} </label> <span> {{ family.addressInfo?.familyLivesWith }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('COUNT_OF_EXTRA_MEMBERS') }} : </label> <span> {{ family.addressInfo?.countOfExtraMembers }} </span> </div>
                      <div class="col-md-6">
                        <label> {{ translatePipe.transform('RESIDENCE_DATE') }} : </label>
                        <pre> {{ family.addressInfo?.residenceStartDate }} {{ family.addressInfo?.residenceEndDate ? ' / ' + family.addressInfo?.residenceEndDate : '' }} </pre>
                      </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('CITY') }} : </label> <span> {{ family.addressInfo?.geoName }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('MOKHTAR') }} : </label> <span> {{ family.addressInfo?.mukhtar }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('ADDRESS') }} : </label> <span> {{ family.addressInfo?.address }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('DESCRIPTIONS') }} : </label> <span> {{ family.addressInfo?.note }} </span> </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <ng-container *ngIf="family.addressData && family.addressData.length">
              <table class="table-data table-bordered table-striped w-100">
                <thead class="with-title">
                  <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('LOCATION_INFO_RECORDS') }} </h4> </th> </tr>
                  <tr>
                    <th style="width: 60px"> # </th>
                    <th> {{ translatePipe.transform('OWNERSHIP_TYPE') }} </th>
                    <th> {{ translatePipe.transform('BUILDING_TYPE') }} </th>
                    <th> {{ translatePipe.transform('HOUSE_AREA') + ' (' + translatePipe.transform('SQUARE_METER') + ')' }} </th>
                    <th> {{ translatePipe.transform('RENT_AMOUNT') + ' (' + translatePipe.transform('IQD') + ')' }} </th>
                    <th> {{ translatePipe.transform('FAMILY_RENT_AMOUNT') + ' (' + translatePipe.transform('IQD') + ')' }} </th>
                    <th> {{ translatePipe.transform('RESIDENCE_START_DATE') }} </th>
                    <th> {{ translatePipe.transform('RESIDENCE_END_DATE') }} </th>
                    <th> {{ translatePipe.transform('CITY') }} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of family.addressData; let index = index">
                    <td> {{ index + 1 }} </td>
                    <td> {{ item.ownershipType }} </td>
                    <td> {{ item.buildingType }} </td>
                    <td> {{ item.houseArea }} </td>
                    <td> {{ item.houseRentAmount }} </td>
                    <td> {{ item.familyRentAmount }} </td>
                    <td> {{ item.residenceStartDate }} </td>
                    <td> {{ item.residenceEndDate }} </td>
                    <td> {{ item.geoName }} </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
            <ng-container *ngIf="family.attachData && family.attachData.address">
              <table>
                <thead class="with-title"> <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('LOCATION_INFO_ATTACH') }} </h4> </th> </tr> </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="row gy-3">
                        <div class="col-md-6"> <label> {{ translatePipe.transform('COUNT_UPLOADED') }} : </label> <span> {{ family.attachData.address.count }} </span> </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </section>
          <section *ngIf="hasData.FAMILY_MEMBER" class="gap-3 flex-column d-flex">
            <table class="table-data table-bordered table-striped w-100">
              <thead class="with-title">
                <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('FAMILY_MEMBER') }} </h3> </th> </tr>
                <tr>
                  <th style="width: 60px"> # </th>
                  <th> {{ translatePipe.transform('PERSON_NAME') }} </th>
                  <th> {{ translatePipe.transform('AGE') }} </th>
                  <th> {{ translatePipe.transform('GENDER') }} </th>
                  <th> {{ translatePipe.transform('MARITAL_STATUS') }} </th>
                  <th> {{ translatePipe.transform('IS_GARDIAN') }} </th>
                  <th> {{ translatePipe.transform('IS_MEMBER_OF_FAMILY') }} </th>
                  <th> {{ translatePipe.transform('RELATIVE_WITH_FATHER') }} </th>
                  <th> {{ translatePipe.transform('INCOME_SPEND_FOR_FAMILY') + ' (' + translatePipe.transform('IQD') + ')' }} </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of family.memberData; let index = index">
                  <td> {{ index + 1 }} </td>
                  <td> {{ item.fullName }} </td>
                  <td> {{ item.age }} </td>
                  <td> {{ item.gender }} </td>
                  <td> {{ item.maritalStatus }} </td>
                  <td> <i *ngIf="item.id" [class]="'bx fw-bolder font-size-20 position-relative mx-1 ' + (item.isSupervisor ? 'bx-check' : 'bx-x')" style="top: 3px"> </i> </td>
                  <td> <i *ngIf="item.id" [class]="'bx fw-bolder font-size-20 position-relative mx-1 ' + (item.calculateInFamilyMemberCount ? 'bx-check' : 'bx-x')" style="top: 3px"> </i> </td>
                  <td> {{ item.familyRelationType }} </td>
                  <td> {{ item.incomeAmountUsed }} </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section *ngIf="hasData.FAMILY_ASSETS" class="gap-3 flex-column d-flex">
            <table class="table-data table-bordered table-striped w-100">
              <thead class="with-title">
                <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('FAMILY_ASSETS') }} </h3> </th> </tr>
                <tr>
                  <th style="width: 60px"> # </th>
                  <th> {{ translatePipe.transform('ASSET') }} </th>
                  <th> {{ translatePipe.transform('COUNT') }} </th>
                  <th> {{ translatePipe.transform('MONTHLY_INCOME_AMOUNT') + ' (' + translatePipe.transform('IQD') + ')' }} </th>
                  <th> {{ translatePipe.transform('QUALITY_STATUS') }} </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of family.assetData; let index = index">
                  <td> {{ index + 1 }} </td>
                  <td> {{ item.assetType }} </td>
                  <td> {{ item.quantity }} </td>
                  <td> {{ item.incomeAmount }} </td>
                  <td> {{ item.qualityStatus }} </td>
                </tr>
              </tbody>
            </table>
            <ng-container *ngIf="family.attachData && family.attachData.asset">
              <table>
                <thead class="with-title"> <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('FAMILY_ASSETS_ATTACH') }} </h4> </th> </tr> </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="row gy-3">
                        <div class="col-md-6"> <label> {{ translatePipe.transform('COUNT_UPLOADED') }} : </label> <span> {{ family.attachData.asset.count }} </span> </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </section>
          <section *ngIf="hasData.OTHER_INCOME" class="gap-3 flex-column d-flex">
            <table class="table-data table-bordered table-striped w-100">
              <thead class="with-title">
                <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('OTHER_INCOME') }} </h3> </th> </tr>
                <tr>
                  <th style="width: 60px"> # </th>
                  <th> {{ translatePipe.transform('DATE') }} </th>
                  <th> {{ translatePipe.transform('DONOR_TYPE') }} </th>
                  <th> {{ translatePipe.transform('AID_TYPE') }} </th>
                  <th> {{ translatePipe.transform('DONATOR') }} </th>
                  <th> {{ translatePipe.transform('AMOUNT') + ' (' + translatePipe.transform('IQD') + ')' }} </th>
                </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of family.incomeData; let index = index">
                <td> {{ index + 1 }} </td>
                <td> {{ item.incomeDate }} </td>
                <td> {{ item.donorType }} </td>
                <td> {{ item.aidType }} </td>
                <td> {{ item.institute || item.relative }} </td>
                <td> {{ item.incomeAmount }} </td>
              </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="100%">
                    <footer class="align-items-center justify-content-between gap-3 d-flex">
                      <div> <label> {{ translatePipe.transform('FAMILY_INCOME_AMOUNT') }} : </label> <span> {{ family.incomeData[0].totalAmounts.totalAmount + ' (' + translatePipe.transform('IQD') + ')' }} </span> </div>
                      <div> <label> {{ translatePipe.transform('ASSET_INCOME_AMOUNT') }} : </label> <span> {{ family.incomeData[0].totalAmounts.assetAmount + ' (' + translatePipe.transform('IQD') + ')' }} </span> </div>
                      <div> <label> {{ translatePipe.transform('FAMILY_MEMBER_INCOME_AMOUNT') }} : </label> <span> {{ family.incomeData[0].totalAmounts.familyMemberAmount + ' (' + translatePipe.transform('IQD') + ')' }} </span> </div>
                      <div> <label> {{ translatePipe.transform('OTHER_INCOME_AMOUNT') }} : </label> <span> {{ family.incomeData[0].totalAmounts.otherIncomeAmount + ' (' + translatePipe.transform('IQD') + ')' }} </span> </div>
                    </footer>
                  </td>
                </tr>
              </tfoot>
            </table>
          </section>
          <section *ngIf="hasData.COSTS" class="gap-3 flex-column d-flex">
            <table class="table-data table-bordered table-striped w-100">
              <thead class="with-title">
                <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('COSTS') }} </h3> </th> </tr>
                <tr>
                  <th style="width: 60px"> # </th>
                  <th> {{ translatePipe.transform('COST_TYPE') }} </th>
                  <th> {{ translatePipe.transform('FAMILY_MEMBER') }} </th>
                  <th> {{ translatePipe.transform('AMOUNT') + ' (' + translatePipe.transform('IQD') + ')' }} </th>
                  <th> {{ translatePipe.transform('PAYER') }} </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of family.costData; let index = index">
                  <td> {{ index + 1 }} </td>
                  <td> {{ item.costType }} </td>
                  <td> {{ item.familyMember }} </td>
                  <td> {{ item.incomeAmount }} </td>
                  <td> {{ item.payer }} </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="100%">
                    <footer class="align-items-center justify-content-between gap-3 d-flex">
                      <div> <label> {{ translatePipe.transform('TOTAL_COST') }} : </label> <span> {{ family.costData[0].totalAmounts.totalCost + ' (' + translatePipe.transform('IQD') + ')' }} </span> </div>
                      <div> <label> {{ translatePipe.transform('ILLNESS_COST') }} : </label> <span> {{ family.costData[0].totalAmounts.illnessCost + ' (' + translatePipe.transform('IQD') + ')' }} </span> </div>
                      <div> <label> {{ translatePipe.transform('FAMILY_COST') }} : </label> <span> {{ family.costData[0].totalAmounts.familyCost + ' (' + translatePipe.transform('IQD') + ')' }} </span> </div>
                    </footer>
                  </td>
                </tr>
              </tfoot>
            </table>
          </section>
          <section *ngIf="hasData.NEEDS" class="gap-3 flex-column d-flex">
            <table>
              <thead class="with-title"> <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('NEEDS') }} </h3> </th> </tr> </thead>
              <tbody>
                <tr>
                  <td>
                    <section class="gap-3 flex-column d-flex">
                      <ng-container *ngIf="family.cashNeedData && family.cashNeedData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('CASH_NEEDS') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('CASH_AID_ACCOUNT_TREE') }} </th>
                              <th> {{ translatePipe.transform('AMOUNT') + ' (' + translatePipe.transform('IQD') + ')' }} </th>
                              <th> {{ translatePipe.transform('AID_REQUEST_DATE') }} </th>
                              <th> {{ translatePipe.transform('PERSON') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of family.cashNeedData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.costDonationTree }} </td>
                              <td> {{ item.amount }} </td>
                              <td> {{ item.requestDate }} </td>
                              <td> {{ item.familyMember }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container *ngIf="family.goodNeedData && family.goodNeedData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('GOOD_NEEDS') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('OBJECTIVE_PRODUCT_AID') }} </th>
                              <th> {{ translatePipe.transform('QUANTITY') }} </th>
                              <th> {{ translatePipe.transform('OBJECTIVE_AID_ACCOUNT_TREE') }} </th>
                              <th> {{ translatePipe.transform('AID_REQUEST_DATE') }} </th>
                              <th> {{ translatePipe.transform('PERSON') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of family.goodNeedData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.good }} </td>
                              <td> {{ item.quantity }} </td>
                              <td> {{ item.costDonationTree }} </td>
                              <td> {{ item.requestDate }} </td>
                              <td> {{ item.familyMember }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container *ngIf="family.serviceNeedData && family.serviceNeedData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('SERVICE_NEEDS') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('AID_TYPE') }} </th>
                              <th> {{ translatePipe.transform('SERVICE') }} </th>
                              <th> {{ translatePipe.transform('COUNT') }} </th>
                              <th> {{ translatePipe.transform('AID_REQUEST_DATE') }} </th>
                              <th> {{ translatePipe.transform('PERSON') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of family.serviceNeedData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.costDonationTree }} </td>
                              <td> {{ item.service }} </td>
                              <td> {{ item.quantity }} </td>
                              <td> {{ item.requestDate }} </td>
                              <td> {{ item.familyMember }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                    </section>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section *ngIf="hasData.ATTACH" class="gap-3 flex-column d-flex">
            <table class="table-data table-bordered table-striped w-100">
              <thead class="with-title">
                <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('ATTACH') }} </h3> </th> </tr>
                <tr>
                  <th style="width: 60px"> # </th>
                  <th> {{ translatePipe.transform('GROUP') }} </th>
                  <th> {{ translatePipe.transform('COUNT_UPLOADED') }} </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of family.attachData.all; let index = index">
                  <td> {{ index + 1 }} </td>
                  <td> {{ item.attachmentGroupTitle }} </td>
                  <td> {{ item.count }} </td>
                </tr>
              </tbody>
            </table>
          </section>
        </td>
      </tr>
    </tbody>
  </table>
</main>

<ng-template #loadingTemplate>
  <app-loader class="d-block my-4"> </app-loader>
</ng-template>
